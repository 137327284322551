import React, { useState, useEffect } from 'react';
import {
    FormGroup,
    Label,
    Input,
    Form
} from "reactstrap";
import './Contect.css'
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
    // const [isHovered, setIsHovered] = useState(false);
    const [checked, setChecked] = useState(true);
    const purpose = localStorage.getItem("Purpose")


    const baseStyle = {
        width: '25%',
        borderRadius: '50px',
        marginTop: '3%',
        marginLeft: '4%',
        backgroundColor: '#4C19A1',
        transition: 'background-color 0.3s ease'
    };

    const hoverStyle = {
        ...baseStyle,
        backgroundColor: '#4C19A1',
        transform: 'scale(1.02)'
    };
    const initialState = {
        FirstName: '',
        LastName: '',
        Country: '',
        State: '',
        Email: '',
        PhoneNumber: '',
        SelectSubject: 'Become a Partner',
        Message: ''
    };

    const [formData, setFormData] = useState(initialState);
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;

        if (type === 'checkbox') {
            setFormData(prev => {
                const newEnquiryType = checked
                    ? [...prev.SelectSubject, value]
                    : prev.SelectSubject.filter(enquiry => enquiry !== value);

                return { ...prev, SelectSubject: newEnquiryType };
            });
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }

        if (formErrors[name]) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
        }
    };

    const validateForm = () => {
        let errors = {};
        if (!formData.FirstName.trim()) errors.firstName = 'First name is required';
        if (!formData.LastName.trim()) errors.lastName = 'Last name is required';
        if (!formData.Country.trim()) errors.country = 'Country is required';
        if (!formData.State.trim()) errors.state = 'State is required';
        if (!formData.Email.trim()) {
            errors.email = 'Email is required';
        } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(formData.Email)) {
            errors.email = 'Invalid email address';
        }
        if (!formData.PhoneNumber.trim()) {
            errors.phoneNumber = 'Phone number is required';
        } else if (!/^\d{10,14}$/.test(formData.PhoneNumber.trim())) {
            errors.phoneNumber = 'Phone number must be between 10 to 14 digits';
        }
        if (!formData.SelectSubject) errors.SelectSubject = 'Please select an enquiry type';
        if (!formData.Message.trim()) errors.message = 'Message is required';

        return errors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
            setLoading(true);

            fetch("https://sheetdb.io/api/v1/v93b80s6cpw6a", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    data: [formData],
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setFormData(initialState);
                    toast.success('Form submitted successfully!', {
                        position: "top-right",
                        autoClose: 2000,
                    });
                })
                .catch((error) => {
                    console.error("Error:", error);
                    toast.error('Failed to submit form.', {
                        position: "top-right",
                        autoClose: 2000,
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setFormErrors(errors);
        }
    };

  

      useEffect(() => {
        document.title = 'Telemo | Contact Us';
     
      }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
        const script = document.createElement('script');
        script.innerHTML = `
          gtag('event', 'conversion', {'send_to': 'AW-16638917336/UXGTCPCcq8YZENj1hv49'});
        `;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <>
            <ExamplesNavbar />
            <div>
                <div>
                    <div className='first-section'>
                        <div className='first-section-img' >
                            <img
                                alt="..."
                                src={require("assets/img/contact1.svg").default}
                                className='contact-img'
                            />
                            <div className='first-section-contact'>
                                Contact to
                                <p style={{ fontWeight: '500' }}>innovate together.</p>
                            </div>
                            <img
                                className='contact-end-img'
                                alt="..."
                                src={require("assets/img/contactSide.svg").default}
                            /></div>
                    </div>
                    <p className='sub-text'>We are working on our telepathy tech, till then you have to write your queries to us 😌.</p>
                    <br />
                    <div className='form-input'  >


                        <ToastContainer />
                        <form onSubmit={handleSubmit} className='form-text'>
                            <div className='first-row'>
                                <label htmlFor="firstName" className='row-start'>
                                    <div style={{ textAlign: 'start', color: '#8E8E8E' }}>First Name</div>
                                    <input
                                        type="text"
                                        id="FirstName"
                                        name="FirstName"
                                        placeholder="First Name"
                                        value={formData.FirstName}
                                        onChange={handleChange}
                                        className='input-box'
                                    />
                                    {formErrors.firstName && <p style={{ color: 'red', textAlign: 'start', }}>{formErrors.firstName}</p>}
                                </label>
                                <label htmlFor="lastName" className='row-start'>
                                    <div style={{ textAlign: 'start', }}>Last Name</div>
                                    <input
                                        type="text"
                                        id="LastName"
                                        name="LastName"
                                        placeholder="Last Name"
                                        value={formData.LastName}
                                        onChange={handleChange}
                                        className='input-box'
                                    />
                                    {formErrors.lastName && <p style={{ color: 'red', textAlign: 'start', }}>{formErrors.lastName}</p>}
                                </label>
                            </div>
                            <br />
                            <div className='first-row'>
                                <label htmlFor="country" className='row-start'>
                                    <div style={{ textAlign: 'start', }}>Country</div>
                                    <input
                                        type="text"
                                        id="Country"
                                        name="Country"
                                        placeholder="Country"
                                        value={formData.Country}
                                        onChange={handleChange}
                                        className='input-box'
                                    />
                                    {formErrors.country && <p style={{ color: 'red', textAlign: 'start', }}>{formErrors.country}</p>}
                                </label>
                                <label htmlFor="state" className='row-start'>
                                    <div style={{ textAlign: 'start', }}>State</div>
                                    <input
                                        type="text"
                                        id="State"
                                        name="State"
                                        placeholder="State"
                                        value={formData.State}
                                        onChange={handleChange}
                                        className='input-box'
                                    />
                                    {formErrors.state && <p style={{ color: 'red', textAlign: 'start', }}>{formErrors.state}</p>}
                                </label>
                            </div>
                            <br />
                            <div className='first-row'>
                                <label htmlFor="email" className='row-start'>
                                    <div style={{ textAlign: 'start', }}>Email</div>
                                    <input
                                        type="text"
                                        id="Email"
                                        name="Email"
                                        placeholder="Email"
                                        value={formData.Email}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        className='input-box'
                                    />
                                    {formErrors.email && <p style={{ color: 'red', textAlign: 'start', }}>{formErrors.email}</p>}
                                </label>
                                <label htmlFor="phoneNumber" className='row-start'>
                                    <div style={{ textAlign: 'start', }}>Phone Number</div>
                                    <input
                                        type="text"
                                        id="PhoneNumber"
                                        name="PhoneNumber"
                                        placeholder="Phone Number"
                                        value={formData.PhoneNumber}
                                        onChange={handleChange}
                                        className='input-box'
                                    />
                                    {formErrors.phoneNumber && <p style={{ color: 'red', textAlign: 'start', }}>{formErrors.phoneNumber}</p>}
                                </label>
                            </div>
                            <br />
                            <div className='select-sub'>
                                Select Subject
                            </div>
                            <div className='Gen-enq'>
                                <div className="round">
                                    General Enquiry
                                    <input
                                        name="SelectSubject"
                                        type="radio"
                                        value="General Enquiry"
                                        onChange={handleChange}
                                        checked={formData.SelectSubject === 'General Enquiry'}
                                        id="general-enquiry-checkbox"
                                    />
                                    <label htmlFor="general-enquiry-checkbox"></label>
                                </div>
                                <div className="round">
                                    Become a Partner
                                    <input
                                        name="SelectSubject"
                                        type="radio"
                                        value="Become a Partner"
                                        onChange={handleChange}
                                        checked={formData.SelectSubject === 'Become a Partner'}
                                        id="partner-checkbox"
                                    />
                                    <label htmlFor="partner-checkbox"></label>
                                </div>
                            </div>
                            {formErrors.SelectSubject && <p style={{ color: 'red', textAlign: 'start', marginTop: '15px' }}>{formErrors.enquiryType}</p>}
                            <br />
                            <div style={{
                                display: 'flex',
                                alignItems: 'self-start',
                                justifyContent: 'flex-start',
                                width: '90%',
                                fontSize: '14px',
                                color: '#8E8E8E',
                                fontWeight: '500'
                            }}>
                                Message
                            </div>
                            <input
                                name="Message"
                                value={formData.Message}
                                onChange={handleChange}
                                className={`textarea ${formData.Message.length > 0 ? 'has-text' : 'no-text'}`}
                            />
                            {formErrors.message && <p style={{ color: 'red', textAlign: 'start', marginTop: '10px' }}>{formErrors.message}</p>}
                            <div style={{ marginLeft: '-5%' }}>
                                <button
                                    type="submit"
                                    className='btsss'
                                    style={isHovered ? hoverStyle : baseStyle}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    disabled={loading}
                                >
                                    Send Message
                                    <img style={{ marginLeft: '10px' }} alt="..." className="path" src={require("assets/img/PaperPlaneTilt.svg").default} />
                                </button>
                            </div>
                        </form>
                        <iframe className='map-section'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.99025074955!2d77.32928067597068!3d28.600069285553335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4503fffffff%3A0x5ad8f15161798a45!2sstartelelogic!5e0!3m2!1sen!2sin!4v1715832562116!5m2!1sen!2sin"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>

                    </div>

                </div>



                <div className="contact">
                    <div className="inner-container">
                        <div className="content-box">

                            {/* Box Content 1 */}
                            <div className="box-content">
                                <div className='contact-border-box'>
                                    <img alt="Email icon" src={require("assets/img/Vector (3).svg").default} style={{ marginLeft: '-15%' }} />
                                    <p
                                        style={{
                                            fontSize: "36px",
                                            color: "#FFFFFF",
                                            fontWeight: '400',
                                            height: 'auto',
                                            fontFamily: 'Space Grotesk',
                                            margin: '5% 0 0 -40%'
                                        }}
                                    >Email</p>
                                </div>
                                <p style={{ display: 'grid', fontSize: '20px', fontWeight: '400', color: '#8E8E8E', fontFamily: 'Space Grotesk' }}>
                                    Drop us a line via email, shoot over your thoughts without fail.
                                </p>
                                <p className="contact-info">hey@telemo.ai</p>
                            </div>

                            {/* Box Content 2 */}
                            <div className="box-content">
                                <div className='contact-border-box'>
                                    <img alt="Phone icon" src={require("assets/img/PhoneCall.svg").default} />
                                    <p
                                        style={{
                                            fontSize: "36px",
                                            color: "#FFFFFF",
                                            fontWeight: '400',
                                            height: 'auto',
                                            fontFamily: 'Space Grotesk',
                                            margin: '5% 0 0 -30%'
                                        }}
                                    >Phone</p>
                                </div>
                                <p style={{ display: 'grid', fontSize: '20px', fontWeight: '400', color: '#8E8E8E', fontFamily: 'Space Grotesk' }}>
                                    Give us a call at the number below, Dial the digits, let's have a chat.
                                </p>
                                <p className="contact-info">+91-120-433-3335</p>
                            </div>

                            {/* Box Content 3 */}
                            <div className="box-content">
                                <div className='contact-border-box'>
                                    <img alt="Office icon" src={require("assets/img/MapTrifold.svg").default} style={{ marginLeft: '-20%' }} />
                                    <p
                                        style={{
                                            fontSize: "36px",
                                            color: "#FFFFFF",
                                            fontWeight: '400',
                                            height: 'auto',
                                            fontFamily: 'Space Grotesk',
                                            margin: '5% 0 0 -50%'
                                        }}
                                    >Office</p>
                                </div>
                                <p style={{ display: 'grid', fontSize: '20px', fontWeight: '400', color: '#8E8E8E', fontFamily: 'Space Grotesk' }}>
                                    Find us at the address right here, Locate our spot without any fear.
                                </p>
                                <p className="contact-info" >
                                F 106, Sector 56, Noida, Uttar Pradesh 201301
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        </>
    );
}

export default ContactUs









